<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Option</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <div class="form-group row">
                    <label
                      for="example-text-input text-right"
                      class="col-sm-3 col-form-label"
                    >
                      Input Type *</label
                    >
                    <div class="col-sm-9">
                      <select
                        class="form-control"
                        v-model="option.optionType"
                        :disabled="mode.viewMode"
                      >
                        <option disabled value="">--Select One Option--</option>
                        <option value="1">Input Condition</option>
                        <option value="2">Input Reading</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12" v-if="option.optionType == 1">
                      <!-- <CInput
               label="Green"
                v-model="ourInputs.checkOptionOK"
                
                horizontal
                :disabled="mode.viewMode||mode.editMode||mode.newMode"
                 :invalid-feedback="'Please Enter the Option Name'"
                  
             /> -->
                      <!-- <MultiSelect
                        label="Green"
                        :options="greenValues"
                        :hide-selected="false"
                        :searchable="true"
                        optionLabel="inputName"
                        :disabled="mode.viewMode"
                        track-by="inputName"
                        :track-ids.sync="option.green"
                        idParamName="inputName"
                        :itemTypeisString="false"
                        invalidFeedback="Select atleast one Option"
                      ></MultiSelect> -->
                      <!-- <div v-if="option.colorType == 1"> -->
                      <!-- <div v-if="option.colorType == 1"> -->
                      <MultiSelect
                        label="Green"
                        :options="greenValues"
                        :hide-selected="false"
                        :searchable="true"
                        optionLabel="inputName"
                        :disabled="mode.viewMode"
                        track-by="inputName"
                        :track-ids.sync="option.green"
                        idParamName="inputName"
                        :itemTypeisString="false"
                        invalidFeedback="Select atleast one Option"
                      ></MultiSelect>
                      <!-- </div> -->
                      <MultiSelect
                        label="Amber"
                        :options="allTopics"
                        :hide-selected="false"
                        :searchable="true"
                        optionLabel="inputName"
                        :disabled="mode.viewMode"
                        track-by="inputName"
                        :track-ids.sync="option.amber"
                        idParamName="inputName"
                        :itemTypeisString="false"
                        invalidFeedback="Select atleast one Option"
                      >
                      </MultiSelect>

                      <MultiSelect
                        label="Red"
                        :options="allReds"
                        :hide-selected="false"
                        :searchable="true"
                        optionLabel="inputName"
                        :disabled="mode.viewMode"
                        track-by="inputName"
                        :track-ids.sync="option.red"
                        idParamName="inputName"
                        :itemTypeisString="false"
                        invalidFeedback="Select atleast one Option"
                      ></MultiSelect>

                      <!-- <CInput
               label="Red"
                v-model="ourInputs.checkOptionNotOk"
                horizontal
               
                :disabled="mode.viewMode||mode.editMode||mode.newMode"
                 :invalid-feedback="'Please Enter the Option Name'"
                  
             /> -->
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12" v-if="option.optionType == 2">
                      <!-- <div class="col-sm-12"> -->
                      <CInput
                        label="Green"
                        v-model="option.green"
                        horizontal
                        :disabled="mode.viewMode"
                        :invalid-feedback="'Please Enter the Option Name'"
                      />
                      <CInput
                        label="Amber"
                        v-model="option.amber"
                        horizontal
                        :disabled="mode.viewMode"
                        :invalid-feedback="'Please Enter the Option Name'"
                      />
                      <CInput
                        label="Red"
                        v-model="option.red"
                        horizontal
                        :disabled="mode.viewMode"
                        :invalid-feedback="'Please Enter the Option Name'"
                      />
                    </div>
                  </div>
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" />Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="save()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import MultiSelect from "../../containers/MultiSelect";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Option",
  components: {
    MultiSelect,
  },
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      checkOptionId: "",
      option: {
        checkId: "0",
        checkOptionId: "0",
        optionId: "",
        inputoption: "",

        // checkOptionName:'',
        optionType: "",
        amber: "",
        green: "",
        red: "",
      },

      ourInputs: {
        checkOptionNotOk: "NOT OK",
        checkOptionOK: "OK",
        input: "",
      },
      allTopics: [],
      // allGreens: [],
      greenValues: [],
      allReds: [],
      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    option: {
      optionType: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.checkOptionId = this.$route.params.checkOptionId;
      this.checkId = this.$route.params.checkId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.newMode = false;
      this.mode.viewMode = true;
      this.checkOptionId = this.$route.params.checkOptionId;
      this.checkId = this.$route.params.checkId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.checkOptionId = this.$route.params.checkOptionId;
      this.checkId = this.$route.params.checkId;
      this.getUser();
    }
  },

  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({
        name: "Options",
        params: { checkId: this.$route.params.checkId },
      });
    },

    save() {
      // fetch(process.env.VUE_APP_API_HOST +"/emapp/web/secure/save/sub/option/"+this.checkId, {
      //   method: "POST",
      //   headers: {
      //     "Content-type": "application/json",
      //   },
      //   body: JSON.stringify(this.option),
      // })
      this.apiPostSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/save/sub/option/" +
          this.checkId,
        this.option
      ).then((option) => {
        console.log(JSON.stringify(option));

        this.$router.push({
          name: "Options",
          params: { checkId: this.$route.params.checkId },
        });
      });
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/option/" +
          this.checkOptionId
      )
        .then((response) => response.json())
        .then(
          (data) => (
            console.log("get user based on optionId", data),
            (this.option = data)
          ),

          console.log("option values", this.option)
        );
    },

    getInputData() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/meta/options/inputdata?colorType=2"
      )
        .then((response) => response.json())
        .then(
          (data) => (
            console.log("data from amber", data),
            (this.allTopics = data),
            console.log("all amber values from api", this.allTopics)
          )
        );
    },
    getGreens() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/meta/options/inputdata?colorType=1"
      )
        .then((response) => response.json())
        .then(
          (data) =>
            // console.log("all green values", data),
            (this.greenValues = data),
          console.log("all green values from api", this.greenValues)
        );
    },
    getReds() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/meta/options/inputdata?colorType=3"
      )
        .then((response) => response.json())
        .then(
          (data) => (
            console.log("all red values", data), (this.allReds = data)
          ),
          console.log("all red values from api", this.allReds)
        );
    },
  },
  mounted() {
    this.getInputData();
    this.getGreens();
    this.getReds();
  },
};
</script>
<style >
input[id="green"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  background-color: #36be3d;
  color: #121312;
  /* pointer-events: none; */
}
input[id="input green"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  background-color: #36be3d;
  color: #121312;
}
input[id="amber"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  background-color: #ffbf00;
  color: white;
}
input[id="red"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  background-color: #df3c0a;
  color: rgb(22, 22, 22);
  /* pointer-events: none; */
}
input[id="input red"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  background-color: #df3c0a;
  color: rgb(22, 22, 22);
}
</style>

